import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Flex, Text, Icon } from "@chakra-ui/react"
import CustomContainer from "../UI/CustomContainer"
import CustomImage from "../UI/CustomImage"
import CustomLink from "../UI/CustomLink"
import { HiOutlineMail, HiOutlinePhone } from "react-icons/hi"

const Footer = ({ data }) => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(filter: { frontmatter: { settingName: { in: ["footer", "menus", "siteinfos"] } } }) {
          nodes {
            frontmatter {
              settingName
              rgpdActive
              logoFooter {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    height: 40
                  )
                }
              }
              addressFooter
              phoneFooter
              mailFooter
              opentimeFooter
              socials {
                facebook
                instagram
              }
              menuMain {
                title
                path
                inNavbar
                inOffcanvas
              }
              menuSecond {
                activeMenuSecond
                menuSecondLinks {
                  title
                  path
                  inNavbar
                  inOffcanvas
                }
              }
            }
          }
        }
      }
    `
  )
  let telLink = "tel:+33"
  let mailTo = "mailto:"
  const footerData = allMarkdownRemark.nodes.find(element => element.frontmatter.settingName === "footer").frontmatter || {}
  const menuData = allMarkdownRemark.nodes.find(element => element.frontmatter.settingName === "menus").frontmatter || {}
  const siteinfosData = allMarkdownRemark.nodes.find(element => element.frontmatter.settingName === "siteinfos").frontmatter || {}
  let isFooterInfos = false
  if (footerData.addressFooter || footerData.phoneFooter || footerData.mailFooter || footerData.opentimeFooter) {
    isFooterInfos = true
  }
  return (
    <Box bg="white">
      <Box py={0} pt={6}>
        <CustomContainer>
          <Flex direction={["column","row"]} justify="space-between" border="1px" borderColor="sand">
            <Flex direction={["column","row"]} justify="flex-start" borderBottomWidth={["1px",0]} borderColor="sand">
              {footerData.logoFooter && (
                <Box borderRightWidth={[0,"1px"]} borderColor="sand" p={6} sx={{"img": {mx: "auto"}}}>
                  <CustomLink href="/">
                    <CustomImage img={footerData.logoFooter} alt="Logo de Khaloca" height="140px" />
                  </CustomLink>
                </Box>
              )}
              {menuData.menuSecond.activeMenuSecond && (
                menuData.menuSecond &&
                <Box>
                  {menuData.menuSecond.menuSecondLinks.map((itemSecond, i) => (
                    <CustomLink
                    href={itemSecond.path}
                    key={i}
                    variant="menuFooterItem">{itemSecond.title}</CustomLink>
                  ))}
                </Box>
              )}
              {isFooterInfos && (
                <Flex
                direction="column"
                flexGrow="1"
                sx={{
                  "a": {
                    textDecoration: "none",
                    "&:hover, &:focus": {
                      textDecoration: "underline",
                      color: "chocolate"
                    }
                  }
                }}>
                  {footerData.addressFooter && (
                    <Text>{footerData.addressFooter}</Text>
                  )}
                  {footerData.opentimeFooter && (
                    <Text>{footerData.opentimeFooter}</Text>
                  )}
                  {footerData.phoneFooter && (
                    <Flex
                    direction="column"
                    justify="center"
                    flex="1"
                    px={6}
                    py={[6,0]}
                    borderRightWidth={[0,"1px"]}
                    borderTopWidth={["1px",0]}
                    borderColor="sand"
                    textAlign={["center","left"]}>
                      <Flex align="center" color="wood" fontSize="xs" fontWeight="bold" mb={1}><Icon as={HiOutlinePhone} w={4} h={4} mr={1} />Téléphone</Flex>
                      <a href={telLink + footerData.phoneFooter.replace(/[- )(]/g, "")}>{footerData.phoneFooter}</a>
                    </Flex>
                  )}
                  {footerData.mailFooter && (
                    <Flex
                    direction="column"
                    justify="center"
                    flex="1"
                    px={6}
                    py={[6,0]}
                    borderTopWidth="1px"
                    borderRightWidth={[0,"1px"]}
                    borderColor="sand"
                    textAlign={["center","left"]}>
                      <Flex align="center" color="wood" fontSize="xs" fontWeight="bold" mb={1}><Icon as={HiOutlineMail} w={4} h={4} mr={1} />Email</Flex>
                      <a href={mailTo + footerData.mailFooter}>{footerData.mailFooter}</a>
                    </Flex>
                  )}
                </Flex>
              )}
            </Flex>
            {footerData.socials && (
              <Flex direction={["row","column"]} justify="center">
                {footerData.socials.facebook && (
                  <Flex
                  justify="center"
                  align="center"
                  bg="facebook"
                  m={6}
                  mb={[6,3]}
                  sx={{
                    "a": {
                      border: "1px solid white",
                      p: "2px",
                      "&:hover": {
                        p: "3px",
                        border: "none"
                      }
                    }
                  }}>
                    <a href={footerData.socials.facebook}>
                      <Box bg="white" p={3}><img src={"/uploads/facebook.svg"} alt="Logo Facebook" /></Box>
                    </a>
                  </Flex>
                )}
                {footerData.socials.instagram && (
                  <Flex
                  justify="center"
                  align="center"
                  bgGradient="linear(to-tr, #feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5)" 
                  m={6}
                  mt={[6,3]}
                  sx={{
                    "a": {
                      border: "1px solid white",
                      p: "2px",
                      "&:hover": {
                        p: "3px",
                        border: "none"
                      }
                    }
                  }}>
                    <a href={footerData.socials.instagram}>
                      <Box bg="white" p={3}><img src={"/uploads/instagram.svg"} alt="Logo Instagram" /></Box>
                    </a>
                  </Flex>
                )}
              </Flex>
            )}
          </Flex>
        </CustomContainer>
      </Box>
      <Box
      fontSize="xs"
      pb={[4,4,5]}
      color="chocolate"
      sx={{
        "a": {
          textDecoration: "none",
          "&:hover, &:focus": {
            textDecoration: "underline",
            color: "text"
          }
        }
      }}>
        <CustomContainer>
          <Flex justify="space-between" direction={["column", "row"]}>
            <Flex align={["center","flex-start"]} direction={["column", "column", "row"]} pb={[4,0]}>
              <CustomLink
              href="/mentions-legales"
              variant="menuFooterBottomItem" mt={[4,4,5]}>Mentions légales</CustomLink>
              {siteinfosData.rgpdActive === true && (
              <CustomLink
              href="/politique-confidentialite"
              variant="menuFooterBottomItem" mt={[4,4,5]} ml={[0,0,8]}>Politique de confidentialité</CustomLink>     
              )}         
            </Flex>
            <Flex justify={["center","center","flex-end"]} mt={[4,4,5]}>
              <Box>Réalisation :&nbsp;</Box>
              <a href="https://www.gotah.com" target="_blank" rel="noreferrer">Gotah</a>
            </Flex>
          </Flex>
        </CustomContainer>
      </Box>
    </Box>    
  )
}

export default Footer