import React from "react"
import { Link as AnchorLink } from "react-scroll"
import { Link as GatsbyLink } from 'gatsby'
import { Link, Box } from "@chakra-ui/react"

const CustomLink = ({ href, variant, children, ...props }) => {
  href = href || "";
  variant = variant || "";
  if (href === "../../../pages/portage") {
    href = "/portage"
  }
  if (href === "portage") {
    href = "/portage"
  }
  if (href === "../../../pages/sommeil") {
    href = "/sommeil"
  }
  if (href === "sommeil") {
    href = "/sommeil"
  }
  const activeClass = "active"
  const internal = /^\/(?!\/)/.test(href)
  const isExternal = href.startsWith("http")
  const anchorLink = href.startsWith("#") || href.startsWith("/#") ? href.replace('#','').replace('/','') : false;
  if (anchorLink) {
    return (
      <Box layerStyle={variant} {...props}>
        <AnchorLink
        activeClass={activeClass}
        to={anchorLink}
        href={href}
        spy={true}
        smooth={true}
        offset={-72}
        duration={500}>
          {children}
        </AnchorLink>
      </Box>
    )
  }
  if (isExternal) {
    return (
      <a href={href} target="_blank" rel="noreferrer">{children}</a>
    )
  }
  if (!href || href.trim().length === 0) {
    return (
      <Box>{children}</Box>
    )
  }
  return (
    <Box layerStyle={variant} {...props}>
      <Link as={GatsbyLink} to={href} partiallyActive={href !== "/" ? false : false} activeClassName={activeClass}>
        {children}
      </Link>
    </Box>
  )
};

export default CustomLink;