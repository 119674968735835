import React from "react"
import ReactMarkdown from "react-markdown"
import { Box, Image } from "@chakra-ui/react"

const Markdown = ({children}) => {
  const MarkdownComponents = {
    p: paragraph => {
      const { node } = paragraph
      
      if (node.children[0].tagName === "img") {
        const image = node.children[0]
        return (
          <Image
            src={image.properties.src}
            alt={image.properties.alt}
            loading="lazy"
          />
        )
      }
      return <p>{paragraph.children}</p>
    }
  }  
  return (
    <Box
    sx={{
      "a": {
        textDecoration: "underline"
      }
    }}>
      <ReactMarkdown children={children} components={MarkdownComponents} />
    </Box>
  )
};

export default Markdown;
