
import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ title, description }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );
  const { pathname } = useLocation()
  const metaTitle = title || site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description
  const pageUrl = site.siteMetadata.siteUrl + pathname
  
  return (
    <Helmet>      
      <html lang="fr" />
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <link rel="canonical" href={pageUrl} />

      <meta property="og:url" content={pageUrl} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={description} />

      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={description} />

      {pathname.startsWith("/produits") && (
        <meta name="robots" content="noindex"></meta>
      )}

      <meta name="google-site-verification" content="_TnYTm3f6ubsPtJmsmPxCMzUK5jgO8i3t3oYPGGhtOY" />
    </Helmet>
  );
}

export default Seo;
