import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react"
import colors from "./colors.js"

// Sizes
// 1 spacing unit is equal to 0.25rem, which translates to 4px by default in common browsers.
// Mental model: If you need a spacing of 40px, divide it by 4. That'll give you 10. Then use it in your component.

// Default breakpoints
// sm: "30em" = 480px
// md: "48em" = 768px
// lg: "62em"= 992px
// xl: "80em" = 1280px
// "2xl": "96em" = 1536px

const theme = extendTheme({
    colors,
    fonts: {
        body: 'Mulish, sans-serif',
        heading: 'Amatic SC, sans-serif',
        monospace: 'Menlo, monospace'
    },
    fontSizes: {
        xs: "0.75rem", // 12px
        sm: "0.875rem", // 14px
        md: "1rem", // 16px
        lg: "1.125rem", // 18px
        xl: "1.25rem", // 20px
        "2xl": "1.5rem", // 24px
        "3xl": "1.875rem", // 30px
        "4xl": "2.25rem", // 36px
        "5xl": "3rem", // 48px
        "6xl": "3.75rem", // 60px
        "7xl": "4.5rem", // 72px
        "8xl": "6rem", // 96px
        "9xl": "8rem", // 128px
    },
    styles: {
        global: {
            body: {
                color: "text",
            },
            "h1,h2,h3,h4,h5,h6": {
                fontFamily: "heading",
                fontWeight: "bold",
                color: "text",
                lineHeight: 1.2,
                mb: 4,
                mt: 8
            },
            h1: {
                fontSize: ["5xl",null,"6xl"]
            },
            h2: {
                fontSize: ["4xl",null,"5xl"]
            },
            h3: {
                fontSize: ["3xl",null,"4xl"]
            },
            h4: {
                fontSize: ["2xl",null,"3xl"]
            },
            h5: {
                fontSize: ["xl",null,"2xl"]
            },
            h6: {
                fontSize: ["lg",null,null,"xl"]
            },
            "a": {
                textDecoration: "none",
                "&:hover": {
                    color: "primary"
                }
            },
            "p,ul,ol,li,blockquote": {
                "&:not(:last-child)": {
                mb: 3
                }
            },
            "ul,ol": {
                pl: "1em",
                listStyle: "none",
            "li": {
                position: "relative",
                pl: "1em",
                "&:before": {
                    color: "primary",
                    position: "absolute",
                    right: "100%"
                }
                }
            },
            "ul": {
                "li": {
                "&:before": {
                    content: "'•'",
                    fontSize: "1em",
                    top: "0.1em"
                }
                }
            },
            "ol": {
                "li": {
                counterIncrement: "my-awesome-counter",
                "&:before": {
                    content: "counter(my-awesome-counter)",
                    fontSize: "0.8em",
                    fontWeight: "bold",
                    top: "0.3em"
                }
                }
            },
            "blockquote": {
                maxW: "400px",
                mx: "auto",
                py: 6,
                px: 12,
                bg: "muted",
                fontStyle: "italic",
                textAlign: "center",
                position: "relative",
                "&:before,&:after": {
                opacity: ".4",
                fontSize: "4rem",
                width: "3rem",
                height: "3rem",
                position: "absolute",
                display: "block"
                },
                "&:before": {
                content: "'“'",
                top: 1,
                left: 1,
                lineHeight: "1"
                },
                "&:after": {
                content: "'”'",
                bottom: 2,
                right: 1
                }
            }
        }
    },
    layerStyles: {
        "menuMainItem": {
            fontWeight: "bold",
            cursor: "pointer",
            "a": {
                textDecoration: "none",
                display: "block",
                "&:after": {
                    content: "''",
                    display: "block",
                    height: "4px",
                    bg: "white",
                    opacity: 0.2,
                    mt: 1,
                    width: 0,
                    transition: "width 150ms linear"
                },
                "&:hover": {
                    textDecoration: "none",
                    color: "white",
                    "&:after": {
                        width: "30px"
                    }
                },
                "&.active": {
                    "&:after": {
                        width: "30px",
                        bg: "white",
                        opacity: 0.8
                    }
                }
            },
            "&.menu-item-sticked": {
                "a": {
                    "&:hover": {
                        color: "text"
                    },
                    "&:after": {
                        bg: "wood"
                    }
                }
            },
            "&.menu-drawer-item": {
                px: 6,
                py: 4,
                borderWidth: "1px 0 0 0",
                borderColor: "sand",
                borderStyle: "solid",
                "a": {
                    "&:hover": {
                        color: "text"
                    },
                    "&:after": {
                        bg: "wood"
                    }
                }
            }
        },
        "menuMainSubItem": {
            fontWeight: "bold",
            cursor: "pointer",
            "a": {
                textDecoration: "none",
                display: "block",
                px: 4,
                py: 2,
                "&:hover": {
                    textDecoration: "none",
                },
                "&.active": {
                    color: "primary"
                }
            },
            "&.menu-drawer-sub-item": {
                fontWeight: "normal"
            }
        },
        "menuFooterItem": {
            py: 2,
        },
        "grid": {
            flexWrap: "wrap",
            mx: -4
        },
        "gridItem": {
            px: 4
        }
    },
    components: {
        Modal: {
            baseStyle: {
                dialog: {
                    pb: 2,
                    pt: 6,
                    m: 6,
                    borderRadius: 0
                }
            }
        },
        Button: {
            baseStyle: {
                textDecoration: "none",
                borderRadius: "base"
            },
            sizes: {
                md: {
                    h: 12
                }
            },
            variants: {
                solid: ({ colorScheme }) => ({
                    borderRadius: "40px",
                    height: 10,
                    pb: "2px",
                    _hover: {
                        color: `white`
                    }
                }),
                outline: ({ colorScheme }) => ({
                    borderRadius: "40px",
                    height: 10,
                    pb: "2px",
                    _hover: {
                        color: `${colorScheme}.600`
                    }
                }),
                round: ({ colorScheme }) => ({
                    w: "50px",
                    h: "50px",
                    borderRadius: "50px",
                    bg: `${colorScheme}.500`,
                    color: `white`,
                    _hover: {
                        color: `white`,
                        bg: `${colorScheme}.600`
                    }
                }),
                "closeBTN": {
                    p: 1,
                    borderRadius: 0,
                    height: "auto",
                    minHeight: "auto",
                    width: "auto",
                    minWidth: "auto",
                    "&:hover": {
                        background: "grayLighter"
                    }
                }
            }
        }
    }
},
withDefaultColorScheme({ colorScheme: "brand" })
)

export default theme