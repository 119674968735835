import React from "react"
import { Container, Box } from "@chakra-ui/react"

const CustomContainer = ({ fluid, children, containerMax, ...props }) => {
  fluid = fluid || false;
  containerMax = containerMax || false;
  return (
    <Container maxW={fluid ? "100%" : ["container.sm","container.md","container.lg","container.xl"]} {...props}>
      <Box maxW={containerMax ? "1000px" : "auto"} mx={containerMax ? "auto" : "0"}>
        {children}
      </Box>
    </Container>
  );
};

export default CustomContainer;
