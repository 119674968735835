
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  Button,
  Box,
  Flex,
  Text,
  Link,
  Icon,
  Stack,
  Collapse,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@chakra-ui/react"
import { IoMdMenu, IoMdClose, IoIosArrowDown } from "react-icons/io"
import CustomContainer from "../UI/CustomContainer"
import CustomImage from "../UI/CustomImage"
import CustomLink from "../UI/CustomLink"

const Header = ({ slug }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  // const btnRef = React.useRef()
  const [stiked, setStiked] = React.useState(false)
  React.useEffect(() => {
    const initialState = window.pageYOffset === 0 ? false : true
    setStiked(initialState)
  }, []);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setStiked(position > 0);
  }
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(filter: { frontmatter: { settingName: { in: ["header", "menus"] } } }) {
          nodes {
            frontmatter {
              settingName
              headerSticky
              logoHeader {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    height: 40
                  )
                }
              }
              logoHeaderTrans {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    height: 65
                  )
                }
              }
              menuMain {
                title
                path
                inNavbar
                inOffcanvas
                menuMainSub {
                  titleSub
                  pathSub
                }
              }
            }
          }
        }
      }
    `
  )
  const headerData = allMarkdownRemark.nodes.find(element => element.frontmatter.settingName === "header").frontmatter || {}
  const menuData = allMarkdownRemark.nodes.find(element => element.frontmatter.settingName === "menus").frontmatter || {}
  const logoBlack = headerData.logoHeader;
  const logoWhite = headerData.logoHeaderTrans;
  const portageActive = slug === "/portage/location/" || slug === "/portage/ateliers/" || slug === "/portage/" ? "active" : ""
  const sommeilActive = slug === "/sommeil/seances/" || slug === "/sommeil/rebozo/" || slug === "/sommeil/" ? "active" : ""
  return (
    <Box
    sx={{
      // position: "-webkit-sticky",
      position: headerData.headerSticky ? "fixed" : "inherit",
      width: "100%",
      zIndex: "5",
      top: "0",
      bg:stiked ? "white" : "transparent",
      boxShadow: stiked ? "md" : "none"
    }}>
      <CustomContainer>
        <Flex align="center" justify="space-between" py={stiked ? [3,4] : [4,5]}>
          <Link href="/" sx={{ "img,div": {transition: "all 200ms ease-in-out"} }}>
            <CustomImage img={logoBlack} alt="Khaloca"  height={stiked ? "40px" : ["66px","86px"]} width="auto" display={slug === "/" ? stiked ? "block" : ["none","none","block"] : "block"} />
            <CustomImage img={logoWhite} alt="Khaloca"  height={stiked ? "40px" : ["66px","86px"]} width="auto" display={slug === "/" ? stiked ? "none" : ["block","block","none"] : "none"} />
          </Link>
          <Box>
            {menuData.menuMain && (
              <Box display={["none","none","none","none","none"]}>
                <DesktopNav menu={menuData.menuMain} stiked={stiked} portageActive={portageActive} sommeilActive={sommeilActive} />
              </Box>
            )}
            <Flex display={["block","block","flex","flex","flex"]} alignItems="center">
              <Button
              aria-label="Ouvrir le menu"
              variant="closeBTN"
              sx={{
                "&:hover": {
                  bg: stiked ? "grayLighter" : "chocolight"
                }
              }}
              onClick={onOpen}>
                  <Text fontSize='xs' mb="0 !important" mr={2} color="white" display="none">MENU</Text>
                  <Icon
                  as={IoMdMenu}            
                  w={stiked ? 6 : 8}
                  h={stiked ? 6 : 8}
                  sx={{
                    color: stiked ? "inherit" : "white",
                    cursor: "pointer"
                  }} />
              </Button>
            </Flex>
          </Box>
        </Flex>
      </CustomContainer>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        trapFocus={false}
      >
        <DrawerOverlay />
        <DrawerContent
        borderLeftWidth="5px"
        borderColor="sand">
          <Button
          aria-label="Fermer le menu"
          variant="closeBTN"
          onClick={onClose}
          sx={{
            pos: "absolute",
            top: 2,
            right: 2
          }}>
            <Icon as={IoMdClose} w={6} h={6} />
          </Button>
          <DrawerBody px={0} pt={16} pb={4}>
            <Box>
              <MobileNav menu={menuData.menuMain} portageActive={portageActive} sommeilActive={sommeilActive} />
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

    </Box>
  )
}

const DesktopNav = (data) => {
  return (
    <Stack direction={'row'} spacing={10}>
      {data.menu.map((item, i) => (
        item.inNavbar && 
        item.menuMainSub ? 
          <Box key={i}>
            <Popover trigger="hover" placement="bottom-start">
              <Box
              layerStyle="menuMainItem"
              className={data.stiked ? "menu-item-sticked" : ""}
              sx={{
                color: data.stiked ? "inherit" : "white"
              }}>
                <PopoverTrigger>
                  <Link href={item.path === "../../../pages/portage" ? "/portage" : item.path === "../../../pages/sommeil" ? "/sommeil" : item.path} className={item.path === "../../../pages/portage" ? data.portageActive : data.sommeilActive}>{item.title}</Link>
                </PopoverTrigger>
              </Box>

              {item.menuMainSub && (
                <PopoverContent
                  boxShadow={'xl'}
                  bg="white"
                  borderWidth="0"
                  borderLeftWidth="5px"
                  borderColor="sand"
                  borderRadius="0"
                  p={2}
                  minW="none"
                  width="auto">
                  <Stack spacing={0}>
                    {item.menuMainSub.map((child, index) => (
                      <CustomLink
                      href={child.pathSub}
                      key={"sub-"+i+index}
                      variant="menuMainSubItem">{child.titleSub}</CustomLink>
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>          
          </Box>
          :
          <CustomLink
          href={item.path}
          key={i}
          variant="menuMainItem"
          className={data.stiked ? "menu-item-sticked" : ""}
          sx={{
            color: data.stiked ? "inherit" : "white"
          }}>{item.title}</CustomLink>
      ))}
    </Stack>
  )
}

const MobileNav = (data) => {
  return (
    <Stack spacing={0}>
      {data.menu.map((navItem, i) => (
        navItem.inOffcanvas && 
        navItem.menuMainSub ? 
          <MobileNavSubItem key={i} my={5} label={navItem.title} href={navItem.path} children={navItem.menuMainSub} portageActive={data.portageActive} sommeilActive={data.sommeilActive}  />
          :
          <MobileNavItem key={i} my={5} label={navItem.title} href={navItem.path} />
      ))}
    </Stack>
  )
}

const MobileNavSubItem = (data) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Box onClick={data.children && onToggle}>
      <Flex justify="space-between" align="center" layerStyle="menuMainItem" className="menu-drawer-item">
        <Link href="#" className={data.href === "../../../pages/portage" ? data.portageActive : data.sommeilActive}>{data.label}</Link>
        <Icon 
        as={IoIosArrowDown} 
        w={4} 
        h={4}
        mt="-0.2em"
        transition={'all .25s ease-in-out'}
        transform={isOpen ? 'rotate(180deg)' : ''} />
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={0}
          ml={6}
          mb={6}
          borderLeftWidth="1px"
          borderColor="grayLighter"
          align={'start'}>
          {data.children &&
            data.children.map((child) => (
              <CustomLink
              href={child.pathSub}
              variant="menuMainSubItem"
              className="menu-drawer-sub-item">{child.titleSub}</CustomLink>
            ))}
        </Stack>
      </Collapse>
    </Box>
  )
}

const MobileNavItem = (data) => {
  return (
    <Box>
      <CustomLink
      href={data.href}
      variant="menuMainItem"
      className="menu-drawer-item">{data.label}</CustomLink>
    </Box>
  )
}

export default Header