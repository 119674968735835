import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Box,
  SimpleGrid,
  Button,
  Icon,
  useDisclosure
} from "@chakra-ui/react"
import { IoMdClose } from "react-icons/io"
import Markdown from "../UI/Markdown"

function isBrowser() {
  return typeof window !== 'undefined';
}
function getValue(key, defaultValue) {
  return isBrowser() && window.localStorage.getItem(key)
    ? JSON.parse(window.localStorage.getItem(key))
    : defaultValue;
}
function setValue(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
}
function useStickyState(defaultValue, key) {
  const [value, setter] = React.useState(() => {
    return getValue(key, defaultValue);
  });

  React.useEffect(() => {
    setValue(key, value);
  }, [key, value]);

  return [value, setter];
}

const CookiesBanner = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { settingName: { eq: "siteinfos" } }) {
          frontmatter {
            sitename
            cookiesText
            rgpdActive
          }
        }
      }
    `
  );
  const data = markdownRemark.frontmatter;
  const location = useLocation();
  if (isBrowser()) {
    initializeAndTrack(location);
  }
  const { isOpen, onClose } = useDisclosure({defaultIsOpen: true})
  const [bannerHidden, setBannerHidden] = useStickyState(false, 'consentCookieHidden')
  const EnableAnalytics = () => {
    document.cookie = 'gatsby-gdpr-google-analytics=true';
    setBannerHidden(true);
  }
  const HideBanner = () => {
    setBannerHidden(true);
  }
  return (
    <>
      {!bannerHidden && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered trapFocus={false}>
          <ModalOverlay />
          <ModalContent maxW="40em">
            <ModalHeader textAlign="center">Bienvenue sur {data.sitename}</ModalHeader>
            <Button
            aria-label="Fermer la pop-up"
            variant="closeBTN"
            onClick={onClose}
            sx={{
              pos: "absolute",
              top: 2,
              right: 2
            }}>
              <Icon as={IoMdClose} w={6} h={6} />
            </Button>
            <ModalBody>
              <Box>
                <Markdown>{data.cookiesText}</Markdown>
              </Box>
            </ModalBody>
            <ModalFooter>
              <SimpleGrid
              columns={[1,3]}
              spacing={[4,6]}
              width="100%"
              sx={{
                "a,button": {
                  flex: ["inherit","1 1 0"]
                }
              }}>
                <Button as="a" href="/politique-confidentialite" colorScheme="gray" variant="outline">En savoir plus</Button>
                <Button onClick={HideBanner}>Refuser</Button>
                <Button onClick={EnableAnalytics}>Autoriser</Button>
              </SimpleGrid>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

export default CookiesBanner;