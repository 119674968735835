import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ChakraProvider } from "@chakra-ui/react"
import theme from "../../theme"
import Seo from "./seo"
import Header from "./Header";
import Footer from "./Footer";
import CookiesBanner from "./CookiesBanner"
import PageTitle from "../UI/PageTitle"

import "@fontsource/amatic-sc/400.css"
import "@fontsource/mulish/400.css"
import "@fontsource/mulish/400-italic.css"
import "@fontsource/mulish/700.css"

const Layout = ({ slug, title, description, titleBG, children }) => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { settingName: { eq: "siteinfos" } }) {
          frontmatter {
            rgpdActive
          }
        }
      }
    `
  );
  const data = markdownRemark.frontmatter;
  return (
    <ChakraProvider theme={theme}>
      <Seo title={title} description={description} />
      <Header slug={slug} />
      {slug !== "/" && <PageTitle title={title} titleBG={titleBG} />}
      {children}
      <Footer />
      {data.rgpdActive === true && <CookiesBanner />}      
    </ChakraProvider>
  );
};

export default Layout;
