const colors = {
  primary: "#E0A561",
  text: '#1D1D1B',
  background: '#fff',
  grayLighter: "#eeeeee",
  grayLight: "#cccccc",
  gray: "#888888",
  grayDark: "#444444",
  muted: '#f6f6f6',
  transparent: "rgba(255,255,255,0)",
  facebook: "#4267B2",
  // brand
  chocolate: '#7B5735',
  chocolight: '#CABCAE',
  orange: '#D87416',
  wood: '#E0A561',
  beige: '#FFEFD8',
  sand: '#F2EADF',
  black: '#1D1D1B',
  // voir https://themera.vercel.app/
  brand: {
    50: "#E0A561",
    100: "#E0A561",
    200: "#E0A561",
    300: "#E0A561",
    400: "#E0A561",
    500: "#E0A561",
    600: "#7B5735",
    700: "#7B5735",
    800: "#7B5735",
    900: "#7B5735"
  }
};
  
export default colors