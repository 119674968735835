import React from "react"
import { getSrc } from "gatsby-plugin-image"
import { Flex, Box, Text } from "@chakra-ui/react"
import CustomContainer from "./CustomContainer"
// const CustomContainer = React.lazy(() => import('./CustomContainer'));

const PageTitle = ({ title, titleBG, theme, ...props }) => {
  const titleBGsrc = getSrc(titleBG)
  return (
    <Flex
      bg="chocolight"
      sx={{
        position: "relative",
        overflow: "hidden",
        minHeight: ["150px", "250px", "250px", "300px"],
        "&:before,&:after": {
          content: "''",
          display: "block",
          position: "absolute",
          zIndex: 1
        },
        "&:before": {     
          display: ["none","none","block"],     
          top: 0,
          left: "50%",
          width: "52vw",
          height: "100%",
          backgroundColor: "#CABCAE",
          backgroundImage: titleBGsrc ? "url(" + titleBGsrc + ")" : null,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundBlendMode: "multiply",
          opacity: .5
        },
        "&:after": {
          width: "100%",
          height: "100%",
          left: 0,
          top: ["160px", "210px", "210px", "252px"],
          background: "white"
        }
      }}
    >
      <Flex
      width="100%"
      sx={{
        position: "relative",
        "&:before": {     
          content: "''",
          position: "absolute",
          zIndex: 1,
          display: ["none","none","block"],     
          top: 0,
          left: "50%",
          width: "52vw",
          height: "100%",
          backgroundImage: "linear-gradient(to right, #CABCAE, transparent)"
        },
        ".chakra-container > div": {
          display: "flex"
        }
      }}>
        <CustomContainer
          pt={["120px",0]}
          sx={{
            position: "relative",
            zIndex: 2,
            mt: "auto"
          }}
        >
          <Box py={3} px={6} bg="wood" minW={["100%","54%"]} w="auto">
            <Text as="h1" color="white" m="0">
              {title}
            </Text>
          </Box>
        </CustomContainer>
      </Flex>
    </Flex>
  )
}

export default PageTitle
