import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Box } from "@chakra-ui/react"
import { Image } from "@chakra-ui/react"

const CustomImage = ({ img, alt, ...props}) => {
  alt = alt || ""
  img = img || false
  const isSVG = img.childImageSharp == null ? true : false
  const image = img ? isSVG ? img.publicURL : getImage(img) : null
  if (image) {
    return (
      <Box {...props}>
        {isSVG && (
          <Image src={image} alt={alt} loading="lazy" {...props} />
        )}
        {!isSVG && (
          <GatsbyImage image={image} alt={alt} />
        )}
      </Box>
    );
  }
  return null;
};

export default CustomImage